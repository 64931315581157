import React from 'react'

import Layout from '../components/layout'
import {Header, Container,Icon} from 'semantic-ui-react'

const MathgamesPage = () => (
  <Layout>
    <Container style={{marginTop: '4em'}} textAlign='center'>
    <Header as='h1' icon>
    <Icon name='gamepad' size='massive'/>
        Math games

        
    </Header>
    </Container>
  </Layout>
)

export default MathgamesPage